import React, { Fragment, useEffect, useState } from 'react';
import Header from '../components/Header';
import AdminTopBar from '../components/TopBar/AdminTopBar';
import TabsComponent from '../components/BtAdminPanel/Tabs';
import Module from '../utils/Module/Module';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import withLogout from '../components/AutoLogOut/withLogout';
import { useUserAndAdminInitials } from '../utils/GetInitials/GetInitials';
import { InitialFlag } from '../utils/Helper/enums';
import Loader from '../library/Loader/loader';
import { IPPFourthLevelHeading, IPPParagraph } from '../library/Heading/Heading';

const BtAdminPanel: React.FC<any> = () => {
  // useState hook to store the timeOfDay state variable
  const [timeOfDay, setTimeOfDay] = useState<string>('');
  const [moduleCount, setModuleCount] = useState<number>(0);
  const navigate: NavigateFunction = useNavigate();
  // Storing the user's name and displaying it as a welcome page
  const userEmail = sessionStorage.getItem('userEmail') as string;

  const { getFirstName } = useUserAndAdminInitials(userEmail, InitialFlag.BtAdmin);

  // function to clear storage when back or forward button is clicked from browser
  useEffect(() => {
    window.addEventListener('popstate', (event) => {
      sessionStorage.clear();
      navigate('/login');
    });
  }, []);
  // useEffect hook to determine the current time of day and set the timeOfDay variable accordingly.
  useEffect(() => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) {
      setTimeOfDay('Good morning');
    } else if (hour >= 12 && hour < 18) {
      setTimeOfDay('Good afternoon');
    } else {
      setTimeOfDay('Good evening');
    }
    const getAdminOrganizationModule = async () => {
      const organizationAdminObj = new Module();
      const result = await organizationAdminObj.getAllModule();
      setModuleCount(result.length);
    };
    void getAdminOrganizationModule();
  }, []);
  return (
    <Fragment>
      <Loader />
      <div className="overflow-hidden relative block">
        {/* ParentHeader component is accepting the child component as a prop and rendering it using props.children */}
        <Header>
          <AdminTopBar />
        </Header>
        <div className="ml-[300px] pt-[100px] z-0">
          <div className="pt-[30px] pr-[20px] pl-[20px]">
            <div className="p-0 pb-[30px] rounded-[5px] mx-0 flex flex-wrap">
              <div className="p-0 w-full">
                <IPPFourthLevelHeading
                  headerText={`${timeOfDay}, ${getFirstName}`}
                  className={'text-black text-[22px] mb-0.5 leading-[1.2]'}
                />
                <IPPParagraph
                  headerText={'Indigo Partner Portal | BT Admin Dashboard'}
                  className={'mb-0 text-[12px] mt-0 block'}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Card Component */}
        <div className="lg:col-start-2 col-span-6 mx-1 lg:mx-0 md:flex lg:flex flex-row justify-between mt-8 lg:mt-18"></div>
        <div className="w-full ml-[300px] pt-[100px]">
          <TabsComponent
            tabs={[
              {
                label: 'Partner Admin',
                key: 'tab1'
              },
              {
                label: `Modules (${moduleCount})`,
                key: 'tab2'
              },
              {
                label: 'Codecommit Analytics',
                key: 'tab3'
              },
              {
                label: 'Preset Configuration',
                key: 'tab4'
              },
              {
                label: 'IPP Audit',
                key: 'tab5'
              }
            ]}
          />
        </div>
      </div>
    </Fragment>
  );
};
export default withLogout(BtAdminPanel);
