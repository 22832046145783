import React, { useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import ResetPassword from '../utils/PasswordRsest/resetPassword';
import SendVerificationCode from '../utils/PasswordRsest/sendVerificationCode';
import PasswordStrengthBar from 'react-password-strength-bar';
import { PasswordRules } from '../components/passwordRules';
import { wrapAsyncFunction } from '../components/UtilityFunction/wrapAsyncFunction';
import InputField from '../library/inputField/inputField';
import Label from '../library/label/label';
import Button from '../library/Button/button';

const ResetPasswordForm: React.FC<any> = (props: any) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [codeStatus, setCodeStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const navigate: NavigateFunction = useNavigate();
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  // Useeffect to handle side effects.
  useEffect(() => {
    // Validate password policy.
    if (newPassword) {
      if (newPassword.length < 16) {
        setPasswordError('Password should be at least 16 characters long');
      } else if (!/[A-Z]/.test(newPassword)) {
        setPasswordError('Password should contain at least one uppercase letter');
      } else if (!/[a-z]/.test(newPassword)) {
        setPasswordError('Password should contain at least one lowercase letter');
      } else if (!/[!@#$%^&*]/.test(newPassword)) {
        setPasswordError('Password should contain at least one special character');
      } else {
        setPasswordError('');
      }
    }
  }, [newPassword]);
  useEffect(() => {
    if (confirmPassword) {
      // Validate password confirmation
      if (confirmPassword !== newPassword) {
        setConfirmPasswordError('Passwords do not match');
      } else {
        setConfirmPasswordError('');
      }
    }
  }, [confirmPassword, newPassword]);
  // Getting useremail from session storage
  const Useremail = sessionStorage.getItem('userEmail');
  // Toggle password show and hide functions.
  const togglePassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = (e: { preventDefault: () => void }): any => {
    e.preventDefault();
    setConfirmPasswordShown(!confirmPasswordShown);
  };
  // Toggle between show and hide button
  const buttonText = passwordShown ? 'Hide' : 'Show';
  const buttonText1 = confirmPasswordShown ? 'Hide' : 'Show';
  // Method to set entered new password.
  const handleNewPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };
  // Method to set entered confirm password.
  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  // Method to set entered code.
  const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationCode(e.target.value);
  };
  // This Method is responsibe to reset the password.
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowAlert(true);
    // Check if any validation errors exist
    if (passwordError || confirmPasswordError) {
      setShowAlert(true);
      return;
    }
    const resetPasswordObj = new ResetPassword({
      email: Useremail,
      confirmationCode,
      newPassword
    });
    try {
      const resetStatus = await resetPasswordObj.resetPassword();
      switch (resetStatus.statusCode) {
        case 201:
          setResponseMessage('Password reset successful');
          break;
        case 400:
          setResponseMessage(resetStatus.body);
          break;
        case 401:
          setResponseMessage(resetStatus.body);
          break;
        default:
          setResponseMessage('Failed to reset the password');
          break;
      }
      if (resetStatus.statusCode === 201) {
        setCodeStatus(true);
      }
    } catch (error) {
      setResponseMessage('An error occurred while resetting the password.');
    } finally {
      setShowAlert(true);
    }
  };

  // Method to close popup.
  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(false);
    setResponseMessage('Please wait');
    if (codeStatus) {
      navigate('/login');
    }
  };
  const isVerificationCodeEmpty = confirmationCode.trim() === '';
  // Method for resend code to useremail
  const handleResendCode = async () => {
    setShowAlert(true);
    const sendVerificationCodeObj = new SendVerificationCode({
      email: Useremail ?? ''
    });
    try {
      const sendCodeStatus = await sendVerificationCodeObj.sendCode();
      switch (sendCodeStatus.statusCode) {
        case 201:
          setResponseMessage('Sent code successfully');
          break;
        case 400:
          setResponseMessage(sendCodeStatus.body);
          break;
        case 404:
          setResponseMessage(sendCodeStatus.body);
          break;
        case 429:
          setResponseMessage(sendCodeStatus.body);
          break;
        default:
          setResponseMessage('Failed to send code');
          break;
      }
      if (sendCodeStatus.statusCode !== 201) {
        navigate('/login');
      }
    } catch (error) {
      setResponseMessage('An error occurred while resending the verification code.');
    } finally {
      setShowAlert(true);
    }
  };
  const isNewPassword = newPassword.trim() === '';
  const isConfirmPassword = confirmPassword.trim() === '';
  const isFormValid =
    !isVerificationCodeEmpty &&
    !passwordError &&
    !confirmPasswordError &&
    !isNewPassword &&
    !isConfirmPassword;
  // method to handle enter key.
  const handleKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isFormValid) {
      event.preventDefault();
      await handleFormSubmit(event);
    }
  };
  const [showMeter, setShowMeter] = useState(false);

  const allRulesValid = PasswordRules(newPassword).every((rule) => rule.isValid);
  return (
    <div>
      <form onSubmit={wrapAsyncFunction(handleFormSubmit)}>
        <Label
          htmlFor="verificationCode"
          className="inline mb-0 mt-2"
          LabelText="Verification Code"
        />
        <InputField
          className="h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px]"
          type="number"
          placeholder="Enter your verification code"
          id="verificationCode"
          value={confirmationCode}
          onChange={handleVerificationCodeChange}
          onKeyUp={handleKeyUp}
        />
        <Label htmlFor="newPassword" className="inline mb-0 mt-2" LabelText="New Password" />
        <Link
          className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
          onClick={(e) => togglePassword(e)}
          to={''}>
          {buttonText}
        </Link>
        <div className="mt-2" />
        <InputField
          className={`h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] ${
            passwordError ? 'border-red' : ''
          }`}
          type={passwordShown ? 'text' : 'password'}
          placeholder="Enter your new password"
          id="newPassword"
          value={newPassword}
          onChange={handleNewPasswordChange}
          onFocus={() => setShowMeter(true)}
          onBlur={() => setShowMeter(false)}
        />
        {showMeter && (
          <div>
            <PasswordStrengthBar minLength={16} password={newPassword} />
            <div className="rules truncate">
              <ul>
                {PasswordRules(newPassword).map((rule) => (
                  <li key={rule.id} className={rule.isValid ? 'rule-passed' : 'rule-failed'}>
                    {rule.isValid ? (
                      <>
                        <span data-check>
                          <svg
                            className="flex-shrink-0 w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                          </svg>
                        </span>
                      </>
                    ) : (
                      <>
                        <span data-uncheck>
                          <svg
                            className="flex-shrink-0 w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round">
                            <path d="M18 6 6 18" />
                            <path d="m6 6 12 12" />
                          </svg>
                        </span>
                      </>
                    )}
                    <div className="pl-[20px] mt-[-1.3rem] pb-[5px] truncate">
                      {rule.description}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <Label
          htmlFor="confirmPassword"
          className="inline mb-0 mt-2"
          LabelText="Confirm Password"
        />
        <Link
          className="pb-0 pt-1.5 cursor-pointer float-right text-[11px] text-black"
          onClick={(e) => toggleConfirmPassword(e)}
          to={''}>
          {buttonText1}
        </Link>
        <div className="mt-2">
          {confirmPasswordError && <p className="text-red-500 text-xs">{confirmPasswordError}</p>}
        </div>

        <InputField
          className={`h-7 w-full p-1.5 text-slate-400 rounded-lg rounded-bg border login-field-border my-1.5 bg-[url('https://img01.bt.co.uk/s/assets/020822/images/logintextboxbg.png')] text-[13px] ${
            confirmPasswordError ? 'border-red' : ''
          }`}
          type={confirmPasswordShown ? 'text' : 'password'}
          placeholder="Enter password again"
          id="confirmPassword"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
        <Button
          className={
            isFormValid && allRulesValid
              ? 'mt-3 ml-16 content-center tracking-normal mb-1 h-10 text-[#fff] text-[17px] w-40 shadow-none border border-login-bt-border cursor-pointer bg-black rounded-[3px]'
              : 'mt-3 ml-16 content-center tracking-normal mb-1 h-10 text-[#fff] text-[17px] w-40 shadow-none border border-login-bt-border cursor-pointer bg-[#737373] rounded-[3px]'
          }
          disabled={!isFormValid}
          buttonText="Reset Password"
        />
      </form>
      <p className="mb-2 mt-2 ml-16 clear-both text-sm pb-0 m-0 text-[#666]">
        <Link className="text-sm text-black underline" onClick={handleResendCode} to={' '}>
          Resend verification code{' '}
        </Link>
        &nbsp;
      </p>
      <CustomPopup onClose={popupCloseAlertHandler} show={showAlert} width={'w-41%'}>
        <div className="max-h-30%">
          <p className="mr-0 mb-0.5 ml-0 text-[17px]">{responseMessage}</p>
        </div>
      </CustomPopup>
    </div>
  );
};

export default ResetPasswordForm;
