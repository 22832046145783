import React from 'react';
import { Tooltip } from 'react-tooltip';
import Button from '../Button/button';
interface IdownloadFile {
  onGuideBook: (e: React.FormEvent) => void;
  onScript: (e: React.FormEvent) => void;
}

const Downloadfile: React.FC<IdownloadFile> = ({ onGuideBook, onScript }) => {
  return (
    <div className="flex flex-row justify-start ml-[330px] mr-[10px] my-[10px] w-1/3 pb-1 rounded-[5px] ml-[19.4rem]">
      <Button
        onclick={onGuideBook}
        className="bg-transparent w-48 mr-6 hover:bg-blue-500 text-blue-700 font-semibold hover:text-black mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded"
        data-tooltip-id="book"
        data-tooltip-content="click here to download GuideBook"
        buttonText="User Guide"
      >
        <Tooltip id="book" />
      </Button>
      <Button
        onclick={onScript}
        className="bg-transparent w-48 mr-6 ml-6 hover:bg-blue-500 text-blue-700 font-semibold hover:text-black mt-2 py-2 px-2 border border-black-500 hover:border-solid rounded"
        data-tooltip-id="script"
        data-tooltip-content="click here to download the set-up script"
        buttonText="Download Script"
      >
        <Tooltip id="script" />
      </Button>
    </div>
  );
};
export default Downloadfile;
