import React, { Fragment, useContext, useEffect, useId, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Button from '../../library/Button/button';
import LogoutConfirmationPopup from './LogoutConfirmationPopup';
import { LoginContext } from '../../context/IppContext';
import { createLogoutChannel } from '../../utils/Helper/broadcastChannel';
import ChangePassword from '../../pages/ChangePassword';
import { useUserAndAdminInitials } from '../../utils/GetInitials/GetInitials';
import { InitialFlag } from '../../utils/Helper/enums';
import notification from '../../assets/img/notification.png';
import ImageTag from '../../library/ImageTag/ImageTag';
import CustomPopup from '../BtAdminPanel/CustomPopup';
import OrgUser from '../../utils/OrgUser/OrgUser';
import OrganizationAdmin from '../../utils/OrganizationAdmin/OrganizationAdmin';
import LockedUsersList from './LockedUsers';
import { removeDuplicates } from '../../utils/Helper/helper';
import IUserList from '../../utils/UserList/UserList.interface';

// interface to declare data types for props
interface ProfileProps {
  setIsLogout: React.Dispatch<React.SetStateAction<boolean>>;
}
/* Profile component for Admin and BtAdmin which contains profile and logut section,as well as when user clicks on logout button it will show popup.
 props are used here to conditonally render background styles for BT logo and profile section. */
const Profile: React.FC<ProfileProps> = ({ setIsLogout }) => {
  const { loginToken, updateToken } = useContext(LoginContext);
  const logoutChannel = createLogoutChannel();
  const navigate = useNavigate();
  const userEmail = sessionStorage.getItem('userEmail') as string;
  // Fetching the path for Admin and btAdmin page
  const location = useLocation();
  const isAdmin = location.pathname === '/admin';
  const btAdmin = location.pathname === '/btadmin';
  const isUser = location.pathname === '/user';
  const uniqueId = useId();

  // Clear the session after successfully logging user out
  const clearSession = () => {
    sessionStorage.clear();
    updateToken('');
    // Notify other tabs to log out
    logoutChannel.postMessage('logout');
  };

  useEffect(() => {
    const handleLogout = () => {
      setIsLogout(true);
      navigate('/login');
    };

    logoutChannel.addEventListener('message', handleLogout);

    // Clean up the event listener on component unmount
    return () => {
      logoutChannel.removeEventListener('message', handleLogout);
    };
  }, [logoutChannel, setIsLogout, navigate]);

  const [visibility, setVisibility] = useState<boolean>(false);
  const popupCloseHandler = (e: boolean) => {
    setVisibility(e);
    setIsLogout(false);
  };
  const onLogoutHandler = () => {
    setVisibility(!visibility);
    setIsLogout(true);
  };

  const onCancelHandler = () => {
    setVisibility(!visibility);
    setIsLogout(false);
  };
  const [showNotification, setShowNotification] = useState(false);
  const onNotificationHandler = () => {
    setVisibility(true);
    setShowNotification(true);
    if (dataUser.length > 0) {
      setIsLogout(true);
    }
  };
  const onCloseHandler = (e: boolean) => {
    setVisibility(e);
    navigate(0);
  };
  // states are used to store values and show the form as per user inputs.
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState(userEmail);
  // Function for Change password button.
  const handleChangeButtonClick = () => {
    setShowForm(!showForm);
    setIsLogout(true);
  };
  // function to Close the dropdown.
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Attach click event listener to the document
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Remove the event listener when the component is unmounted
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  // function to show the change password form.
  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const { calculatedInitials } = isAdmin
    ? useUserAndAdminInitials(userEmail, InitialFlag.Admin)
    : btAdmin
    ? useUserAndAdminInitials(userEmail, InitialFlag.BtAdmin)
    : isUser
    ? useUserAndAdminInitials(userEmail, InitialFlag.User)
    : useUserAndAdminInitials(userEmail, InitialFlag.Admin);

  const { getFullName } = isAdmin
    ? useUserAndAdminInitials(userEmail, InitialFlag.Admin)
    : btAdmin
    ? useUserAndAdminInitials(userEmail, InitialFlag.BtAdmin)
    : isUser
    ? useUserAndAdminInitials(userEmail, InitialFlag.User)
    : useUserAndAdminInitials(userEmail, InitialFlag.Admin);
  const UserList: IUserList[] = [];
  let uniqueArray: any[] | (() => any[]) = [];
  const [dataUser, setDataUser]: any = useState(uniqueArray);
  const checkIsLocked = (result: (IUserList | null)[]) => {
    for (let i = 0; i < result.length; i++) {
      if (result[i]?.isLocked) {
        UserList.push(result[i]!);
      }
    }
  };
  if (btAdmin) {
    useEffect(() => {
      const getAllUsers = async () => {
        const allUserObj = new OrgUser({ email: userEmail ?? '' });
        const result = await allUserObj.getAllUser();
        checkIsLocked(result);
        uniqueArray = removeDuplicates(UserList);
      };
      const getAdminOrganization = async () => {
        const organizationAdminObj = new OrganizationAdmin();
        const result = await organizationAdminObj.getAllOrganization();
        checkIsLocked(result);
        uniqueArray = removeDuplicates(UserList);
        setDataUser(uniqueArray);
      };
      void getAdminOrganization();
      void getAllUsers();
    }, []);
  }
  return (
    <div className="flex justify-between items-center text-black">
      <div className="pr-5 text-right" />
      {btAdmin && (
        <div>
          <Button
            id={`bell-${uniqueId}`}
            aria-label="Bell"
            onclick={onNotificationHandler}
            className=""
            buttonText={''}>
            <strong className="relative inline-flex items-center px-1 py-1.5 text-xs font-medium">
              <span className="absolute -top-2 -right-2 h-10 w-11 rounded-full bg-green-600 flex justify-center items-center items">
                {dataUser.length > 0 && (
                  <span className="text-red font-bold">{dataUser.length}</span>
                )}
              </span>
              <ImageTag src={notification} width={'40px'} height={'30px'} alt={''} name={'Bell'} />
            </strong>
          </Button>
          {showNotification && (
            <div>
              {dataUser.length > 0 && (
                <CustomPopup onClose={onCloseHandler} show={visibility} width={'w-41%'}>
                  <div>
                    <div className="text-[22px] text-center mb-[10px]">Locked Users</div>
                    <LockedUsersList lockedUsers={dataUser} />
                  </div>
                </CustomPopup>
              )}
            </div>
            // <CustomPopup onClose={onCloseHandler} show={visibility}>
            //   {dataUser.length > 0 ? (
            //     <div>
            //       <div className="text-[22px] text-center mb-[10px]">Locked Users</div>
            //       <LockedUsersList lockedUsers={dataUser} />
            //     </div>
            //   ) : (
            //     <div
            //       className="px-6 py-8 text-center"
            //       style={{ paddingTop: '5rem', fontWeight: 'bold' }}>
            //       <h1 className="text-bold">No data found ☹️ !!</h1>
            //     </div>
            //   )}
            // </CustomPopup>
          )}
        </div>
      )}
      <div ref={dropdownRef}>
        <Button
          onclick={handleButtonClick}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded flex items-center"
          buttonText={''}>
          <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-white border border-gray-100 rounded-full">
            <span className="font-medium text-gray-100 dark:text-gray-300">
              {calculatedInitials}
            </span>
          </div>
          <svg
            className="w-4 h-4 text-gray-800 dark:text-white ml-[7px] pr-[4px] mt-[5px]"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 8">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1"
            />
          </svg>
        </Button>
        {isOpen && (
          <div className="absolute z-50 mt-2 py-2 pl-[8px] pr-[8px] text-justify right-0 bg-white border border-gray-300 rounded shadow-lg">
            <div className="pl-[2px] pr-[4px] py-3 text-sm text-gray-100 dark:text-gray-300">
              <div className="truncate">{getFullName}</div>
              <div className="font-medium truncate">
                {isAdmin ? 'Admin' : btAdmin ? 'Super Admin' : isUser ? 'Organisation User' : ''}
              </div>
            </div>
            <hr />
            <Link
              to={''}
              className="text-trans cursor-not-allowed block pl-[2px] pr-[4px] py-2 text-gray-800 hover:bg-gray-200">
              Profile
            </Link>
            <Link
              className="block pl-[2px] pr-[4px] py-2 text-gray-800 hover:bg-gray-200 truncate"
              onClick={handleChangeButtonClick}
              to={''}>
              Change password
            </Link>
            {showForm && <ChangePassword userEmail={email} />}
            <Button
              className="block pl-[2px] pr-[4px] py-2 text-gray-800 hover:bg-gray-200"
              buttonText="Logout"
              onclick={onLogoutHandler}
            />
            <LogoutConfirmationPopup
              onClose={popupCloseHandler}
              visibility={visibility}
              onCancelHandler={onCancelHandler}
              clearSession={clearSession}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
