import React, { useState, useEffect, useRef, ChangeEvent, useCallback } from 'react';
import Footer from '../components/Footer';
import UserEmailInfo from '../components/register/userEmailInfo';
import UserDetails from '../components/register/userDetails';
import PartnerCompanyDetails from '../components/register/partnerComapnyDetails';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import BtLogo from '../library/Logo/BtLogo';
import User from '../utils/User/User';
import CustomPopup from '../components/BtAdminPanel/CustomPopup';
import { handleApiResponse } from '../utils/Helper/apiResponseHandler';
import withLogout from '../components/AutoLogOut/withLogout';
import NotFoundPage from './NotFoundPage';
import { emailRegex } from '../utils/regex';

const Register: React.FC<any> = () => {
  interface IError {
    errorMessage: string | null;
    errorConfirmMessahe: string | null;
  }
  const [inputEmail, setInputEmail] = useState<string>('');
  const [isEmailValid, setisEmailValid] = useState<boolean>(false);
  const [isEmailEnable, setEmailEnable] = useState<boolean>(false);
  const [error, setError] = useState<IError>({
    errorMessage: null,
    errorConfirmMessahe: null
  });
  const [isSaveUserDetails, setSaveUserDetails] = useState<boolean>(false);
  const [isSaveUserPcd, setSaveUserPcd] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [fullname, setFullname] = useState<string>('');
  const [lname, setLname] = useState<string>('');
  const [jobTitle, setjobTitle] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [password, setPassword]: any = useState({});
  const [partnerCompanyName, setpartnerCompanyName] = useState<string>('');
  const [companyHeadQuaterAddress, setcompanyHeadQuaterAddress] = useState<string>('');
  const [description, setdescription] = useState<string>('');
  const [doj, setDoj] = useState<string>('');
  const [showLoader, setShowLoader] = useState(false);

  const [message, setmessage] = useState<string>('');
  // useState takes the initial state as an argument and returns an array of two entries. The first item contains the state value, which currently is false.
  const { btadmin } = queryString.parse(useLocation().search);
  const [show, setShow] = useState(false);
  const [partnerDomains, setPartnerDomains] = React.useState<string[]>([]);
  const navigate = useNavigate();
  const popupCloseHandler = (e: boolean) => {
    setShow(e);
    navigate('/btadmin');
  };

  const clickRef: any = useRef(null);

  const isEmailDomainValid = (email: string, domains: string[]): boolean => {
    let isDomainValid = true;
    if (domains.length > 0) {
      const emailDomain = email.split('@')[1].trim() ?? ''; // Domain from email
      isDomainValid = domains.includes(emailDomain);
    }
    return isDomainValid;
  };
  // Function to check if email is vaild and sets error accordingly except when email is not active or email format is not correct
  const validateEmail = (email: string, domains: string[]): void => {
    if (!isEmailEnable) {
      return;
    }
    if (email.length === 0) {
      setisEmailValid(false);
      setError({
        errorMessage: 'Please enter email.',
        errorConfirmMessahe: error.errorConfirmMessahe
      });
    } else if (!emailRegex.test(email)) {
      setisEmailValid(false);
      setError({
        errorMessage: 'Please enter a valid email.',
        errorConfirmMessahe: error.errorConfirmMessahe
      });
    } else if (email.trim().length > 40) {
      setisEmailValid(false);
      setError({
        errorMessage: 'Email can not be more than 40 characters.',
        errorConfirmMessahe: error.errorConfirmMessahe
      });
    } else if (!isEmailDomainValid(email, domains)) {
      setisEmailValid(false);
      setError({
        errorMessage: 'Please use the organisation specific domain.',
        errorConfirmMessahe: error.errorConfirmMessahe
      });
    } else {
      setisEmailValid(true);
      setError({
        errorMessage: null,
        errorConfirmMessahe: error.errorConfirmMessahe
      });
    }
  };

  const handleOrganisationEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value !== undefined) {
      const email = event.target.value;
      if (event.target.id === 'userEmail') {
        setInputEmail(event.target.value);
        validateEmail(email, partnerDomains);
      } else {
        setInputEmail(inputEmail);
        setError({
          errorMessage: error.errorMessage,
          errorConfirmMessahe: null
        });
      }
    }
  };

  const createRequestBody = () => {
    return {
      email: inputEmail,
      fullname,
      jobTitle,
      phone,
      password: password.password,
      partnerCompanyName,
      companyHaedquaterAddress: companyHeadQuaterAddress,
      description,
      domains: partnerDomains
    };
  };
  const createUser = async () => {
    setShowLoader(true);
    sessionStorage.removeItem('CompanyName');
    sessionStorage.removeItem('Company Email');
    const userObj = new User(createRequestBody());
    const userstatus = await userObj.createUser();
    const errorMessage =
      userstatus.statusCode === 500
        ? 'Something went wrong....'
        : userstatus.statusCode === 403
        ? 'Email already Registered'
        : userstatus.statusCode === 404
        ? 'Requested Organisation User details is not found in the database.'
        : userstatus.statusCode === 406
        ? 'Organization Admin already exists in the database.'
        : userstatus.statusCode === 400
        ? '400 bad request error.'
        : 'Organisation Admin creation failed. Please try after sometime..';
    const successMessage = userstatus.statusCode === 201 ? 'Organisation created successfully' : '';
    const response = handleApiResponse(userstatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setTimeout(() => {
      setShowLoader(false);
      setmessage(responseBody);
      setShow(true);
    }, 1500);
  };

  const updatePartnerDomains = (domains: string[]) => {
    validateEmail(inputEmail, domains);
    setPartnerDomains([...domains]);
  };

  if (btadmin !== 'true') {
    return (
      <Routes>
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    );
  }
  return (
    <div className="visible block box-border text-[16px] min-h-screen w-full flex-1">
      {btadmin ? (
        <>
          <Link className="lg:max-h-12 " to="/btadmin" title="Home" rel="home">
            <BtLogo />
          </Link>
          <div className="max-w-[1220px] pl-[30px] pr-[30px] bg-white mr-auto ml-auto">
            <div className="block">
              <Link className="cursor-pointer font-[400] text-black no-underline" to={'/btadmin'}>
                <div className="h-2.5"></div>
                <i
                  className="relative inline-block -top-[2px] w-[8px] h-[8px] border-t-[1px] border-r-[1px] mr-[0.5em] ml-0.5 border-r-[bt-purple] border-t-[bt-purple]"
                  style={{
                    transform: 'rotate(228deg)',
                    content: ''
                  }}
                ></i>
                Back
              </Link>
            </div>
            <UserDetails
              isSaveUserDetails={isSaveUserDetails}
              title={title}
              setTitle={setTitle}
              fullname={fullname}
              setFullname={setFullname}
              lname={lname}
              setLname={setLname}
              jobTitle={jobTitle}
              setjobTitle={setjobTitle}
              setPhone={setPhone}
              phone={phone}
              setSaveUserDetails={setSaveUserDetails}
            />
            <PartnerCompanyDetails
              isSaveUserDetails={isSaveUserDetails}
              isSaveUserPcd={isSaveUserPcd}
              setSaveUserPcd={setSaveUserPcd}
              setpartnerCompanyName={setpartnerCompanyName}
              partnerCompanyName={partnerCompanyName}
              companyHeadQuaterAddress={companyHeadQuaterAddress}
              setcompanyHeadQuaterAddress={setcompanyHeadQuaterAddress}
              doj={doj}
              setDoj={setDoj}
              setdescription={setdescription}
              description={description}
              setSaveUserDetails={setSaveUserDetails}
              updatePartnerDomains={updatePartnerDomains}
            />
            <UserEmailInfo
              isSaveUserDetails={isSaveUserDetails}
              isSaveUserPcd={isSaveUserPcd}
              inputEmail={inputEmail}
              handleOnChange={handleOrganisationEmailChange}
              clickRef={clickRef}
              error={error}
              isEmailValid={isEmailValid}
              password={password}
              setPassword={setPassword}
              setSaveUserPcd={setSaveUserPcd}
              createUser={createUser}
              setEmailEnable={setEmailEnable}
              showLoader={showLoader}
            />
            <CustomPopup onClose={popupCloseHandler} show={show} width={'w-41%'}>
              <div className="max-h-30% ">
                <p className="mt-0 mr-0 mb-0.5 ml-0 text-[17px]">{message}</p>
              </div>
            </CustomPopup>
          </div>

          {/* <Footer /> */}
          <div
            className="w-full mt-[150px]"
            style={isSaveUserDetails ? { paddingTop: '1px' } : { padding: '0px' }}
          >
            <Footer />
          </div>
        </>
      ) : (
        <Routes>
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      )}
    </div>
  );
};

export default withLogout(Register);
