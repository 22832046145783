import React, { Dispatch, Fragment, useEffect, useState } from 'react';
import CustomPopup from '../CustomPopup';
import ReactLoading from 'react-loading';
import ModuleAccessList from './ModuleAccessList';
import { IPPHeading } from '../../../library/Heading/Heading';
import apiCallWrapper from '../../../apiwrapper/apiCallWrapper';
import { IPreset } from '../../../utils/ModulePresets/preset.interface';

interface Props {
  show: boolean;
  onClose: (set: boolean) => void;
  preset: IPreset;
  setIsUpdated: Dispatch<React.SetStateAction<boolean>>;
}
// Popup to display Module access of preset
const ShowPreset = ({ show, onClose, preset }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [baseModules, setBaseModules] = useState<[]>([]);
  const [selectedAccessTypes, setSelectedAccessTypes] = useState<{ [moduleName: string]: string }>(
    {}
  );

  useEffect(() => {
    const fetchModule = async () => {
      setIsLoading(true);
      try {
        const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
        const subPath = '/organization/module';
        const url = `${backEndServerUrl}/organization/module`;
        const Axios = await apiCallWrapper(subPath, 'GET');
        const response = await Axios.get(url);
        const allModules = response.data;
        // Filter modules where isSystemModule is false
        const nonSystemModules = allModules.filter(
          (module: any) =>
            !module.isSystemModule &&
            preset.moduleList.findIndex((item) => item.moduleName === module.moduleName) !== -1
        );
        setBaseModules(nonSystemModules);
      } catch (error) {
        console.error('Error fetching system modules:', error);
      }
      setIsLoading(false);
    };
    void fetchModule();
  }, []);

  useEffect(() => {
    setSelectedAccessTypes(() => {
      const initilSelect: { [moduleName: string]: string } = {};
      preset.moduleList.forEach((element) => {
        initilSelect[element.moduleName] = element.accessType;
      });
      return initilSelect;
    });
  }, [preset]);

  return (
    <CustomPopup show={show} onClose={onClose} width={'w-41%'}>
      <Fragment>
        <div>
          <div>
            <IPPHeading className="font-bold text-2xl" headerText={preset.presetName} />
          </div>
          <br />
          {isLoading ? (
            <ReactLoading
              className="flex justify-center my-[4rem]"
              type="bubbles"
              color="#000000"
              height={100}
              width={350}
            />
          ) : (
            <ModuleAccessList
              name={preset.presetName}
              baseModules={baseModules}
              selectedAccessTypes={selectedAccessTypes}
              setSelectedAccessTypes={setSelectedAccessTypes}
              defaultAccessTypes={selectedAccessTypes}
              systemModules={[]}
              disabled={true}
            />
          )}
        </div>
      </Fragment>
    </CustomPopup>
  );
};

export default ShowPreset;
