import React, { Fragment, useCallback, useEffect, useState } from 'react';
import CustomPopup from './CustomPopup';
import Button from '../../library/Button/button';
import IModuleList from '../../utils/ModuleList/ModuleList.interface';
import apiCallWrapper from '../../apiwrapper/apiCallWrapper';
import ReactLoading from 'react-loading';
import { ModuleAccessLevel } from '../../utils/Helper/enums';
import axios from 'axios';
import ConfirmationPopUp from '../../library/ConfirmationPopUp/ConfirmationPopUp';
import useNetworkConnection from '../../library/NetworkConnection/NetworkConnection';
import InputField from '../../library/inputField/inputField';
import { IPPFourthLevelHeading, IPPHeading, IPPParagraph } from '../../library/Heading/Heading';
import { IPreset } from '../../utils/ModulePresets/preset.interface';

/**
 * This common props takes in one parameter.
 * @organisationName - This prop is used for storing the organisation name.
 **/
interface AssignedModuleProps {
  organisationName: string | null;
  presets: IPreset[] | [];
}

// This component is responsible for managing and displaying the assignment of access levels for various modules within an organization, allowing users to select access levels and submit the changes.
export const AssignedModuleForm: React.FC<AssignedModuleProps> = ({
  organisationName,
  presets
}) => {
  const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
  const [isLoading, setIsLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [responseMessage, setResponseMessage] = useState('Please Wait...');
  const [showForm, setShowForm] = useState(false);

  const [selectedAccessTypes, setSelectedAccessTypes] = useState<{ [moduleName: string]: string }>(
    {}
  );
  const [activeTab, setActiveTab] = useState('Base Modules');
  const [systemModuleName, setSystemModuleName] = useState<any>([]);
  const [baseModules, setBaseModules] = useState<any[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [defaultAccessTypes, setDefaultAccessTypes] = useState<{ [moduleName: string]: string }>(
    {}
  );
  const [selectedPreset, setselectedPreset] = useState<number>(-1);

  // Reusable hook to check internet connectivity
  const { isConnected } = useNetworkConnection();

  const selectPreset = (index: number): void => {
    setselectedPreset(index);
    if (index === -1) {
      setSelectedAccessTypes(defaultAccessTypes);
      return;
    }
    const selectedPresetValue = presets[index];
    const moduleList: any = {};
    selectedPresetValue?.moduleList?.forEach((element) => {
      moduleList[element.moduleName] = element.accessType;
    });
    setSelectedAccessTypes((prvs) => {
      systemModuleName.forEach((module: any) => {
        moduleList[module.moduleName] = prvs[module.moduleName] ?? ModuleAccessLevel.NOACCESS;
      });
      return moduleList;
    });
  };

  const handleRadioButtonChange = useCallback(
    (moduleName: string, accessType: ModuleAccessLevel) => {
      setSelectedAccessTypes((prevAccessTypes) => {
        const newAccessTypes = {
          ...prevAccessTypes,
          [moduleName]: accessType
        };
        setselectedPreset(-1);
        return newAccessTypes;
      });
    },
    [defaultAccessTypes]
  );

  const isPartialAccessSelected = (moduleName: string) => {
    return selectedAccessTypes[moduleName] === ModuleAccessLevel.PARTIALACCESS;
  };

  const isFullAccessSelected = (moduleName: string) => {
    return selectedAccessTypes[moduleName] === ModuleAccessLevel.FULLACCESS;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subPath = '/organization/admin';
        const response = await apiCallWrapper(subPath, 'GET');
        const responseDb = await response.get(`${backEndServerUrl}/organization/admin`);
        const data = responseDb.data;

        // Find the module objects for the specified partnerCompanyName
        const moduleObject = data.filter(
          (item: any) => item.partnerCompanyName === organisationName
        );

        moduleObject.forEach((moduleObj: any) => {
          moduleObj.moduleList.forEach((selectedModule: any) => {
            const accessType = selectedModule.accessType;

            setSelectedAccessTypes((prevAccessTypes) => ({
              ...prevAccessTypes,
              [selectedModule.moduleName]: accessType
            }));

            setDefaultAccessTypes((prevDefaultAccessTypes) => ({
              ...prevDefaultAccessTypes,
              [selectedModule.moduleName]: accessType
            }));
          });
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    void fetchData();
  }, [organisationName]);

  useEffect(() => {
    const fetchModule = async () => {
      try {
        const backEndServerUrl = String(process.env.REACT_APP_BACK_END_SERVER_URL ?? '');
        const jwtToken = sessionStorage.getItem('jwtToken');
        const preferredRole = sessionStorage.getItem('preferredRole');
        const apiKey = String(process.env.REACT_APP_API_KEY ?? '');
        const config = {
          headers: {
            Authorization: jwtToken,
            'x-api-key': apiKey,
            'Role-Name': preferredRole
          }
        };
        const url = `${backEndServerUrl}/organization/module`;
        const response = await axios.get(url, config);
        const allModules = response.data;

        // Filter system modules based on the isSystemModule property
        const systemModules = allModules.filter((module: any) => module.isSystemModule);
        // Filter modules where isSystemModule is false
        const nonSystemModules = allModules.filter(
          (module: { isSystemModule: boolean }) => !module.isSystemModule
        );
        setSystemModuleName(systemModules);
        setBaseModules(nonSystemModules);
        setTimeout(() => {
          setIsLoading(false);
        }, 10000);
      } catch (error) {
        console.error('Error fetching system modules:', error);
      }
    };
    void fetchModule();
  }, []);

  const handleButtonClick = useCallback(() => {
    setShowLoader(false);
    setShowAlert(false);
    setShowForm(true);
  }, []);

  const switchTab = useCallback((tab: string) => {
    setActiveTab(tab);
  }, []);

  const submitModule = (): void => {
    setSubmissionInProgress(true);
    setShowLoader(true);
    sessionStorage.removeItem('CompanyName');
    sessionStorage.removeItem('Company Email');
    setTimeout(() => {
      setShowLoader(false);
      setShowAlert(true);
      setSubmissionInProgress(false);

      // Filter out modules with "No Access" from the selectedAccessTypes object
      const filteredAccessTypes = Object.keys(selectedAccessTypes)
        .filter((moduleName) => selectedAccessTypes[moduleName] !== ModuleAccessLevel.NOACCESS)
        .reduce<{ [moduleName: string]: string }>((obj, moduleName) => {
          obj[moduleName] = selectedAccessTypes[moduleName];
          return obj;
        }, {});

      const moduleArray = Object.entries(filteredAccessTypes).map(([moduleName, accessType]) => ({
        moduleName,
        accessType
      }));

      const formattedData = {
        orgname: organisationName,
        moduleList: moduleArray
      };

      sessionStorage.setItem('Module Length', moduleArray.length.toString());
      sessionStorage.setItem('Organisation Name', formattedData.orgname!);

      const subPath = '/module/assigneModule';
      const axiosWithRoles = apiCallWrapper(subPath, 'POST');
      axiosWithRoles
        .post(`${backEndServerUrl}/module/assigneModule`, formattedData)
        .then((response) => {
          const responseBody = 'Module Assigned Successfully!!';
          setResponseMessage(responseBody);
          // Update default access types after successful submission
          setDefaultAccessTypes(selectedAccessTypes);
        })
        .catch((error) => {
          throw error;
        });
    }, 20000);
  };

  const popupCloseAlertHandler = useCallback((e: boolean) => {
    setShowAlert(e);
    setShowForm(e);
    setselectedPreset(-1);
  }, []);

  const closeModal = useCallback(() => {
    setSelectedAccessTypes(defaultAccessTypes);
    setShowForm(false);
    setShowAlert(false);
    setShowConfirmation(false);
    setselectedPreset(-1);
  }, [defaultAccessTypes]);

  const openModal = useCallback(() => {
    setShowForm(true);
    setShowAlert(false);
    setShowConfirmation(false);
  }, []);

  const popupCloseHandler = useCallback(
    (e: boolean) => {
      if (!submissionInProgress) {
        let hasChanges = false;
        for (const moduleName in selectedAccessTypes) {
          const currentAccessType = selectedAccessTypes[moduleName];
          const defaultAccessType = defaultAccessTypes[moduleName];
          const isChangedFromDefault =
            defaultAccessType !== undefined
              ? currentAccessType !== defaultAccessType
              : currentAccessType !== ModuleAccessLevel.NOACCESS;
          if (isChangedFromDefault) {
            hasChanges = true;
            break;
          }
        }
        if (hasChanges) {
          setShowForm(e);
          setShowConfirmation(true);
        } else {
          setShowForm(false);
        }
      } else {
        setShowForm(false); // If submission is in progress, close the form without showing the confirmation
      }
      setselectedPreset(-1);
    },
    [defaultAccessTypes, selectedAccessTypes, submissionInProgress]
  );

  return (
    <Fragment>
      {isConnected && (
        <div>
          <Button
            className="text-white bg-black rounded-[8px] text-[12px] px-1 ml-2"
            buttonText="Access"
            onclick={handleButtonClick}
          />
          {showForm && (
            <CustomPopup onClose={popupCloseHandler} show={showForm} width={'w-41%'}>
              <div className="flex start justify-between px-4 py-4 pt-[0rem]">
                <IPPFourthLevelHeading
                  headerText={'Assign Modules'}
                  className={'mb-0 leading-normal text-2xl text-black'}
                />
              </div>
              <br />
              {isLoading ? (
                <ReactLoading
                  className="flex justify-center my-[4rem]"
                  type="bubbles"
                  color="#000000"
                  height={100}
                  width={350}
                />
              ) : (
                <>
                  <div className="flex gap-2">
                    <div className="max-h-[34rem] border-r border-black">
                      <div className="inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center border-b border-black">
                        Presets
                      </div>
                      <div className="flex flex-col gap-2 items-center justify-center pt-2 max-h-[30rem] overflow-hidden overflow-y-auto">
                        <Button
                          className={`inline-block mb-0 h-[50px] text-[17px] w-full cursor-pointer rounded-[10px] px-4 py-2 ${
                            selectedPreset === -1 && 'bg-gray text-black'
                          }`}
                          buttonText="Custom"
                          onclick={() => {
                            selectPreset(-1);
                          }}
                        />
                        {presets?.map((preset, index) => (
                          <Button
                            key={preset.presetName + index}
                            className={`inline-block mb-0 h-[50px] text-[17px] w-full cursor-pointer rounded-[10px] px-4 py-2 ${
                              selectedPreset === index && 'bg-gray text-black'
                            }`}
                            buttonText={preset.presetName}
                            onclick={() => {
                              selectPreset(index);
                            }}
                          />
                        ))}
                      </div>
                    </div>
                    <div>
                      <div className="border-b border-black">
                        <div
                          className={
                            activeTab === 'Base Modules'
                              ? 'inline-block bg-gray text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center active bg-gray cursor-pointer'
                              : 'inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center cursor-pointer'
                          }
                          onClick={() => switchTab('Base Modules')}>
                          Base Modules
                        </div>
                        <div
                          className={
                            activeTab === 'Custom Modules'
                              ? 'inline-block bg-gray text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center active bg-gray cursor-pointer'
                              : 'inline-block text-black rounded-t-lg lg:w-[18rem] py-4 px-4 text-[18px] text-center cursor-pointer'
                          }
                          onClick={() => switchTab('Custom Modules')}>
                          Custom Modules
                        </div>
                      </div>
                      <div>
                        {activeTab === 'Base Modules' && baseModules.length > 0 ? (
                          <div className="position-relative grow shrink basis-auto p-[1rem]block max-h-[30rem] overflow-y-auto">
                            <table className="w-100% border-collapse">
                              <thead className="table-header-group align-middle bg-[#f8f8f8] sticky-header">
                                <tr className="text-black text-left table-row align-inherit">
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Module Name
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Full Access
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Partial Access
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    No Access
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {baseModules
                                  .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                                    a.moduleName.localeCompare(b.moduleName)
                                  )
                                  .map((element: IModuleList, index: number) => (
                                    <tr key={index}>
                                      <div className="mt-[5px]">
                                        <td
                                          className={`px-10 py-4 text-center ${
                                            (selectedAccessTypes[element.moduleName!] !==
                                              ModuleAccessLevel.NOACCESS ||
                                              defaultAccessTypes[element.moduleName!] !==
                                                undefined) &&
                                            selectedAccessTypes[element.moduleName!] !==
                                              defaultAccessTypes[element.moduleName!]
                                              ? 'bg-gray rounded-[50px]'
                                              : ''
                                          }`}>
                                          {element.moduleName}
                                        </td>
                                      </div>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[2.2rem]">
                                          <InputField
                                            id={`full-access-radio-${index}`}
                                            type="radio"
                                            name={element.moduleName!}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={isFullAccessSelected(element.moduleName!)}
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                element.moduleName!,
                                                ModuleAccessLevel.FULLACCESS
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[3rem]">
                                          <InputField
                                            id={`partial-access-radio-${index}`}
                                            type="radio"
                                            name={element.moduleName!}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={isPartialAccessSelected(element.moduleName!)}
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                element.moduleName!,
                                                ModuleAccessLevel.PARTIALACCESS
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[2rem]">
                                          <InputField
                                            id={`no-access-radio-${index}`}
                                            type="radio"
                                            name={element.moduleName!}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={
                                              selectedAccessTypes[element.moduleName!] ===
                                                ModuleAccessLevel.NOACCESS ||
                                              (!isPartialAccessSelected(element.moduleName!) &&
                                                !isFullAccessSelected(element.moduleName!))
                                            }
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                element.moduleName!,
                                                ModuleAccessLevel.NOACCESS
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          systemModuleName.length === 0 && (
                            <div
                              className="px-6 py-4 text-center"
                              style={{ paddingTop: '5rem', fontWeight: 'bold' }}>
                              <IPPHeading
                                headerText={'No data found ☹️ !!'}
                                className={'text-bold'}
                              />
                            </div>
                          )
                        )}
                        {activeTab === 'Custom Modules' && systemModuleName.length > 0 ? (
                          <div className="position-relative grow shrink basis-auto p-[1rem]block max-h-[30rem] overflow-y-auto">
                            <table className="w-100% border-collapse">
                              <thead className="table-header-group align-middle bg-[#f8f8f8] sticky-header">
                                <tr className="text-black text-left table-row align-inherit">
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Module Name
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Full Access
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    Partial Access
                                  </th>
                                  <th className="font-semibold text-sm uppercase px-6 py-4 text-center">
                                    No Access
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {systemModuleName
                                  .sort((a: { moduleName: string }, b: { moduleName: any }) =>
                                    a.moduleName.localeCompare(b.moduleName)
                                  )
                                  .map((module: IModuleList, index: number) => (
                                    <tr key={index}>
                                      <div className="mt-[5px]">
                                        <td
                                          className={`px-10 py-4 text-center ${
                                            (selectedAccessTypes[module.moduleName!] !==
                                              ModuleAccessLevel.NOACCESS ||
                                              defaultAccessTypes[module.moduleName!] !==
                                                undefined) &&
                                            selectedAccessTypes[module.moduleName!] !==
                                              defaultAccessTypes[module.moduleName!]
                                              ? 'bg-gray rounded-[50px]'
                                              : ''
                                          }`}>
                                          {module.moduleName}
                                        </td>
                                      </div>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[2.2rem]">
                                          <InputField
                                            id={`full-access-radio-${index}`}
                                            type="radio"
                                            name={module.moduleName!}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={isFullAccessSelected(module.moduleName!)}
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                module.moduleName!,
                                                ModuleAccessLevel.FULLACCESS
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[3rem]">
                                          <InputField
                                            id={`partial-access-radio-${index}`}
                                            type="radio"
                                            name={module.moduleName!}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={isPartialAccessSelected(module.moduleName!)}
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                module.moduleName!,
                                                ModuleAccessLevel.PARTIALACCESS
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 text-center">
                                        <div className="flex items-center ml-[2rem]">
                                          <InputField
                                            id={`no-access-radio-${index}`}
                                            type="radio"
                                            name={module.moduleName!}
                                            className={
                                              'w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 accent-black'
                                            }
                                            checked={
                                              selectedAccessTypes[module.moduleName!] ===
                                                ModuleAccessLevel.NOACCESS ||
                                              (!isPartialAccessSelected(module.moduleName!) &&
                                                !isFullAccessSelected(module.moduleName!))
                                            }
                                            onChange={() =>
                                              handleRadioButtonChange(
                                                module.moduleName!,
                                                ModuleAccessLevel.NOACCESS
                                              )
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          baseModules.length === 0 && (
                            <div
                              className="px-6 py-4 text-center"
                              style={{ paddingTop: '5rem', fontWeight: 'bold' }}>
                              <IPPHeading
                                headerText={'No data found ☹️ !!'}
                                className={'text-bold'}
                              />
                            </div>
                          )
                        )}
                        <div className="sticky bottom-[-16px] bg-white p-4 absolute inset-x-0">
                          <Button
                            className="inline-block mb-0 h-[42px] text-[#fff] text-[17px] w-[9rem] cursor-pointer bg-black rounded-[10px] px-4 py-2"
                            buttonText="Submit"
                            onclick={submitModule}
                            loading={showLoader}
                            disabled={showLoader}
                          />
                        </div>
                        {showAlert && (
                          <CustomPopup
                            onClose={popupCloseAlertHandler}
                            show={showAlert}
                            width={'w-41%'}>
                            <div className="max-h-30% ">
                              <IPPParagraph
                                headerText={`${responseMessage}`}
                                className={'mr-0 mb-0.5 ml-0 text-[17px]'}
                              />
                            </div>
                          </CustomPopup>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </CustomPopup>
          )}
          {showConfirmation && (
            <ConfirmationPopUp show={showConfirmation}>
              <div className="max-h-30% ">
                <IPPParagraph
                  headerText={'You have made changes. Do you want to discard?'}
                  className={'mr-0 mb-0.5 ml-0 text-[23px]'}
                />
                <br />
                <div className="flex justify-center">
                  <Button
                    className="h-10 px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black mr-3"
                    buttonText="Yes"
                    onclick={closeModal}
                  />
                  <Button
                    className="px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3"
                    buttonText="No"
                    onclick={openModal}
                  />
                </div>
              </div>
            </ConfirmationPopUp>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default AssignedModuleForm;
