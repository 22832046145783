// string only contains character, space and . as well as . can only be somewhere in middle
export const userNameRegex = /^[a-zA-Z ]+(?:\.[A-Za-z ]+)*$/;
// string only contains character, space and / , . - \n
export const characterAndFewSpecialCharacters = /^[a-zA-Z0-9-/, .\n]+$/;
// string only contains character, space and .
export const onlyCharacterAndDotRegex = /^[a-zA-Z .]+$/;
// Empty string regex
export const emptyStringRegex = /^(?!\s*$).+/;
// Email Regex
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
