import React, { Fragment, useEffect, useRef, useState } from 'react';
import Button from '../../library/Button/button';
import InputField from '../../library/inputField/inputField';
import Label from '../../library/label/label';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { ConditionalStyleComponent } from '../ConditionalStyle';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { JobTitle } from '../../utils/Helper/enums';
import containsNumber from '../../library/NumberValidations/containsNumber';
import { userNameRegex } from '../../utils/regex';
import { validateNamePattern } from '../../utils/Helper/helper';

const UserDetails: React.FC<any> = (props: any) => {
  interface IError {
    errorMessage: string | null;
    errorConfirmMessage: string | null;
  }

  const { btadmin } = queryString.parse(useLocation().search);

  // State for enabling the partner admin details
  const [isNameEnable, setNameEnable] = useState<boolean>(false);
  const [isJobEnable, setJobEnable] = useState<boolean>(false);

  // Error States
  const [errorName, setErrorName] = useState<IError>({
    errorMessage: null,
    errorConfirmMessage: null
  });
  const [errorJob, setErrorJob] = useState<IError>({
    errorMessage: null,
    errorConfirmMessage: null
  });

  // Refs for input fields
  const clickRefName: any = useRef(null);
  const clickRefJob: any = useRef(null);

  const isValidName = (name: string): boolean => {
    const regex = userNameRegex;
    return regex.test(name);
  };

  const isValidJob = (job: string): boolean => {
    const regex = /^(?!\s*$).+/;
    return regex.test(job);
  };

  // Event handlers for input changes
  const handleOnChangeName = (event: any): any => {
    const inputValue = event.target.value;
    const inputId = event.target.id;
    if (inputValue !== undefined) {
      if (inputId === 'fn') {
        props.setFullname(inputValue);
        const validationError = validateNamePattern(inputValue);
        if (typeof validationError === 'string') {
          setErrorName({
            errorMessage: validationError,
            errorConfirmMessage: null
          });
        } else {
          setErrorName({
            errorMessage: null,
            errorConfirmMessage: errorName.errorConfirmMessage
          });
        }
      } else {
        props.setFullname(props.fullname);
        setErrorName({
          errorMessage: errorName.errorMessage,
          errorConfirmMessage: null
        });
      }
    }
  };

  const handleOnChangeJob = (event: any): any => {
    const inputValue = event.target.value;
    const inputId = event.target.id;
    if (inputValue !== undefined) {
      if (inputId === 'job') {
        props.setjobTitle(inputValue);
        setErrorJob({
          errorMessage: null,
          errorConfirmMessage: errorJob.errorConfirmMessage
        });
      } else {
        props.setjobTitle(props.jobTitle);
        setErrorJob({
          errorMessage: errorJob.errorMessage,
          errorConfirmMessage: null
        });
      }
    }
  };

  const handleOnChangePhoneNumber = (event: any): any => {
    props.setPhone(event);
  };

  const isValid =
    !props.fullname ||
    !props.jobTitle ||
    containsNumber(props.fullname) ||
    !isValidName(props.fullname);

  // UseEffect hooks to handle outside click events for various input fields and performs validation based on the enable flags.
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isNameEnable &&
        clickRefName.current &&
        document.contains(clickRefName.current as Node) &&
        event.target !== clickRefName.current
      ) {
        const inputValue = clickRefName.current.value.trim();
        if (!inputValue) {
          setErrorName({ errorMessage: 'Name can not be empty', errorConfirmMessage: null });
        } else if (!errorName.errorMessage) {
          setErrorName({ errorMessage: null, errorConfirmMessage: null });
        } else {
          setErrorName({ errorMessage: null, errorConfirmMessage: null });
        }
      }
      if (
        isJobEnable &&
        clickRefJob.current &&
        document.contains(clickRefJob.current as Node) &&
        event.target !== clickRefJob.current
      ) {
        const inputValue = clickRefJob.current.value.trim();
        if (!inputValue) {
          setErrorJob({ errorMessage: 'Please select role', errorConfirmMessage: null });
        } else if (!errorName.errorMessage) {
          setErrorJob({ errorMessage: null, errorConfirmMessage: null });
        } else {
          setErrorJob({ errorMessage: null, errorConfirmMessage: null });
        }
      }
    };
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isNameEnable, isJobEnable]);

  return (
    <Fragment>
      <ConditionalStyleComponent
        condition={!props.isSaveUserDetails}
        trueStyle={{ display: 'block', marginBottom: '30px' }}
        falseStyle={{
          display: 'block '
        }}
        isSaveUserDetails={false}
        isSaveUserPcd={false}
      >
        <h2
          className={
            !props.isSaveUserDetails
              ? 'py-5 px-[30px] my-0 -mx-[30px] bg-[#666] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]'
              : 'py-5 px-[30px] my-0 -mx-[30px] bg-[#008A00] text-white cursor-pointer text-[1em] z-[1] min-h-[45px]'
          }
          style={{ borderTop: '1px solid #fff' }}
        >
          {btadmin ? (
            <span className="text-white block">
              Partner Admin details
              {props.isSaveUserDetails && <span> - </span>}
              {props.isSaveUserDetails ? `${props.title} ${props.fullname}` : ''}
            </span>
          ) : (
            <span className="text-white block">
              Your details
              {props.isSaveUserDetails && <span> - </span>}
              {props.isSaveUserDetails ? `${props.title} ${props.fullname}` : ''}
            </span>
          )}
          {props.isSaveUserDetails && (
            <span className="block float-right h-4 text-left">
              <span
                className="flot-left py-4 px-[5px] text-[white] inline-block relative -top-[49px] underline"
                onClick={() => {
                  props.setSaveUserDetails(false);
                }}
              >
                Edit
              </span>
              <span
                className="inline-block relative -top-[34px]"
                style={{ transform: 'translate(0%,1%)' }}
              >
                <span
                  className="bg-[100%] bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/BT_Iconography_Confirmation_message_white.png')] h-12 w-12 overflow-hidden inline-block"
                  style={{
                    backgroundPosition: '0 0',
                    backgroundSize: '100% 100%'
                  }}
                ></span>
              </span>
            </span>
          )}
        </h2>
      </ConditionalStyleComponent>
      {!props.isSaveUserDetails && (
        <div className="block">
          {btadmin ? (
            <h3 className="pr-[25%] mt-[14px] mb-2.5 text-[21px] leading-[1.5em] text-[#333]">
              Please enter partner admin details
            </h3>
          ) : (
            <h3 className="pr-[25%] mt-[14px] mb-2.5 text-[21px] leading-[1.5em] text-[#333]">
              Please enter your personal details
            </h3>
          )}
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left required-field"
              LabelText="Name:"
            />
            <div className="px-0 w-[31.25%] table">
              <InputField
                id="fn"
                type="text"
                maxLength={60}
                className="h-10 block w-full py-[7px] px-[14px] rounded bg-white test-[#555]"
                value={props.fullname}
                onChange={handleOnChangeName}
                reference={clickRefName}
                required={true}
                onClick={(e) => {
                  setNameEnable(true);
                }}
                style={
                  errorName.errorMessage == null
                    ? { border: '1px solid #666' }
                    : { border: '1px solid #e60014' }
                }
              />
              {errorName.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errorName.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left required-field"
              LabelText=" Portal role:"
            />
            <div className="px-0 w-[31.25%] table">
              <select
                id="job"
                value={props.jobTitle}
                onChange={handleOnChangeJob}
                className="w-full bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/br_down.png')] bg-[32px] h-10 py-[0.1em] rounded-[5px] pl-[5px] text-[#555] pr-[33px] m-0 bg-no-repeat overflow-hidden appearance-none"
                style={{
                  backgroundPosition: 'right',
                  border: '1px solid #666',
                  backgroundSize: '32px'
                }}
                required={true}
                ref={clickRefJob}
                onClick={(e) => {
                  setJobEnable(true);
                }}
              >
                <option value="" label="" />
                <option value={JobTitle.Admin} label={JobTitle.Admin} />
              </select>
              {errorJob.errorMessage && (
                <div className="h-auto w-full float-left bg-[#E60014] text-white mt-2.5 relative table mb-[15px]">
                  <span className="px-0 bg-[url('https://my.bt.com/s/apps/appsconnectedcare/img/up_icon_red.png')] bg-no-repeat absolute -top-[10px] left-2.5 w-[24px] h-[11px] mt-0 mr-0 mb-2.5 ml-2.5"></span>
                  <div className="pt-2.5 pr-2.5 pb-2.5 pl-[15px]">
                    <span className="left-[15px] py-0 h-10 w-10 m-0 text-[.95em]">
                      {' '}
                      {errorJob.errorMessage}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="w-full my-5 mx-0 relative table border-separate">
            <Label
              className="text-right leading-[40px] pr-2.5 pl-0 w-[31.25%] mb-[5px] float-left"
              LabelText="Mobile Phone:"
            />

            <div className="px-0 w-[31.25%] table">
              <PhoneInput
                country={'gb'}
                placeholder="Enter your mobile no"
                value={props.phone}
                onChange={handleOnChangePhoneNumber}
                inputProps={{
                  required: true
                }}
                inputStyle={{
                  width: '100%',
                  height: '40px',
                  border: '1px solid rgb(102, 102, 102)'
                }}
                buttonStyle={{ border: '1px solid rgb(102, 102, 102)' }}
              />
            </div>
          </div>
          <div className="block"></div>
          <div className="my-5 mx-0 w-full" style={{ borderBottom: '#ddd solid 1px' }}></div>
          <div className="float-right">
            <ConditionalStyleComponent
              condition={!isValid}
              trueStyle={{
                backgroundColor: '#000',
                color: 'white',
                border: 'black',
                paddingTop: '11px',
                paddingBottom: '11px',
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
                marginTop: '5px',
                marginBottom: '5px',
                marginLeft: '0',
                marginRight: '0',
                width: '100%',
                textAlign: 'center',
                height: '2.5rem',
                fontSize: '0.9rem',
                borderRadius: '0.375rem',
                display: 'inline-block'
              }}
              falseStyle={{
                backgroundColor: '#808080',
                cursor: 'not-allowed',
                color: 'white',
                border: '#808080',
                opacity: '0.5',
                paddingTop: '11px',
                paddingBottom: '11px',
                paddingLeft: '1.25rem',
                paddingRight: '1.25rem',
                marginTop: '5px',
                marginBottom: '5px',
                marginLeft: '0',
                marginRight: '0',
                width: '100%',
                textAlign: 'center',
                height: '2.5rem',
                fontSize: '0.9rem',
                borderRadius: '0.375rem',
                display: 'inline-block'
              }}
              isSaveUserDetails={false}
              isSaveUserPcd={false}
            >
              <Button
                className={''}
                disabled={isValid}
                onclick={() => {
                  props.setSaveUserDetails(true);
                }}
                buttonText="Continue"
              />
            </ConditionalStyleComponent>
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default UserDetails;
