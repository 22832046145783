import axios from 'axios';

const apiCallWrapper = (path: string, method: string) => {
  const axiosInstance = axios.create();
  axiosInstance.interceptors.request.use((config) => {
    const loginPath: string = '/login';
    const forgotPath: string = '/reset/otp';
    const resetPath: string = '/reset/confirmpassword';
    const redirctUrlPath: string = '/resendurl';
    const orgAdminPath: string = '/organization/admin';
    const orgModulePath: string = '/organization/module';
    const orgUserPath: string = '/organization/user';
    const assignedModulePath: string = '/module/assigneModule';
    const auditPath: string = '/auditHistory';
    const jwtToken = sessionStorage.getItem('jwtToken');
    const role = sessionStorage.getItem('preferredRole');
    const apiKey = String(process.env.REACT_APP_API_KEY ?? '');

    const setCommonHeaders = () => {
      config.headers.Authorization = `${jwtToken}`;
      config.headers['x-api-key'] = apiKey;
      config.headers['Role-Name'] = role;
    };

    if (path === orgAdminPath) {
      if (method === 'GET' || method === 'PUT' || method === 'DELETE') {
        setCommonHeaders();
      } else {
        config.headers['x-api-key'] = apiKey;
      }
    } else if (path === auditPath && method === 'GET') {
      config.headers['x-api-key'] = apiKey;
      config.headers['Role-Name'] = role;
    } else if (path === orgModulePath && method === 'GET') {
      setCommonHeaders();
    } else if (path === orgUserPath && method === 'POST') {
      setCommonHeaders();
    } else if (path === orgUserPath && method === 'GET') {
      config.headers['Role-Name'] = role;
    } else if (path === assignedModulePath && method === 'POST') {
      setCommonHeaders();
    } else if (
      path === loginPath ||
      path === redirctUrlPath ||
      path === forgotPath ||
      path === resetPath
    ) {
      config.headers['x-api-key'] = apiKey;
    } else {
      setCommonHeaders();
    }

    return config;
  });

  return axiosInstance;
};

export default apiCallWrapper;
