import React, { useState, useEffect, useContext } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import WelcomeHeader from '../components/TopBar/WelcomeHeader';
import login from '../assets/img/login.png';
import MfaSetupQrCode from '../components/MfaSetupQrCode';
import OtpValidate from '../components/OtpValidate';
import UserLogin from '../utils/UserLogin/UserLogin';
import { UserType } from '../utils/Helper/enums';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { LoginContext } from '../context/IppContext';
import { decrypt } from '../utils/Helper/encryption';

const Mfa: React.FC<any> = (props: any) => {
  const [secretCode, setSecretCode] = useState(sessionStorage.getItem('secretCode'));
  const [accessToken, setAccessToken] = useState(sessionStorage.getItem('accessToken') ?? '');
  const [isMfaSetupDone, setIsMfaSetupDone] = useState<boolean>(
    sessionStorage.getItem('isMfaSetup') === 'true'
  );
  const [email, setEmail] = useState(sessionStorage.getItem('userEmail'));
  const [userPass, setUserPass] = useState(sessionStorage.getItem('userPassword') ?? '');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { loginToken, updateToken } = useContext(LoginContext);
  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('userEmail')) {
      navigate('/login');
    }
  }, []);

  return (
    <>
      <div className="m-0 p-0 align-baseline">
        <div className="z-10 pt-3.5 min-h-[900px] clear-both w-full relative overflow-hidden ">
          {/* Header start */}
          <Header>
            <WelcomeHeader />
          </Header>
          {/* Header End */}
          {/* Border Start */}
          <div
            className="w-full absolute z-50 mt-0 clear-both h-px"
            style={{
              borderTop: '1px solid #ccc'
            }}
          ></div>
          <div className="clear-both"></div>
          {/* Border End */}
          {/* Login Panel start */}
          <div
            className="sm:w-[980px] sm:pt[5rem] mx-auto mt-0 mb-0 px-4 min-h-[380px] min-w-[288px] table z-[5] relative "
            style={{
              backgroundPositionX: '0px',
              backgroundPositionY: '0px'
            }}
          >
            <div className="block sm:mt-[8rem] mt-[11rem] sm:p-[0.5rem] rounded-lg shadow-lg sm:my-8 sm:mx-[19.625rem] pointer-events-auto sm:float-none sm:justify-center bg-white">
              {isMfaSetupDone ? (
                <OtpValidate
                  isLoading={isLoading}
                  onOtpSubmission={async (code: string) => {
                    setIsLoading(true);
                    const userLoginObj = new UserLogin({
                      email: email ?? '',
                      password: userPass ?? ''
                    });
                    const loginStatus = await userLoginObj.doLogin(code);
                    const responseData: any = loginStatus?.body ?? {};
                    const userType = responseData?.userType ?? Number.MAX_SAFE_INTEGER;
                    switch (loginStatus.statusCode) {
                      case 201:
                        switch (userType) {
                          case UserType.BtAdmin:
                            setIsLoading(false);
                            navigate('/btadmin');
                            break;
                          case UserType.OrganizationAdmin:
                            setIsLoading(false);
                            updateToken(JSON.stringify(responseData));
                            navigate('/admin');
                            break;
                          case UserType.OrganizationUser:
                            setIsLoading(false);
                            updateToken(JSON.stringify(responseData));
                            navigate('/user');
                            break;
                          default:
                            break;
                        }
                        break;
                      case 410:
                        setIsLoading(false);
                        alert('Sorry OTP mismatched');
                        break;
                      case 401:
                        setIsLoading(false);
                        alert('Invalid credentials.');
                        navigate('/login');
                        break;
                      case 403:
                        setIsLoading(false);
                        alert('Sorry something went wrong, please try again later.');
                        break;
                      case 500:
                        setIsLoading(false);
                        alert('Sorry something went wrong, please try again later.');
                        break;
                      default:
                    }
                    // Storing the login token and email address of the user
                    sessionStorage.setItem('user-type', userType);
                  }}
                />
              ) : (
                <MfaSetupQrCode
                  isLoading={isLoading}
                  secretCode={secretCode ?? ''}
                  onOtpVerification={async (code: string) => {
                    setIsLoading(true);
                    const userLoginObj = new UserLogin({
                      email: email ?? '',
                      password: userPass ?? ''
                    });
                    const loginStatus = await userLoginObj.userMfaSetup(code, accessToken);
                    const responseData: any = loginStatus?.body ?? {};
                    if (loginStatus.statusCode === 201) {
                      setIsLoading(false);
                      setIsMfaSetupDone(true);
                      alert('Your MFA setup is successfull.');
                      navigate('/login');
                    } else {
                      setIsLoading(false);
                      alert('Sorry something went wrong, please try again later.');
                    }
                  }}
                />
              )}
            </div>
          </div>
          {/* Background Image start */}
          <div className="w-full mt-0 mr-auto mb-0 min-h-[64.000em] absolute top-[91px] text-center z-0 block">
            <img
              src={login}
              className="relative object-cover bg-center bg-cover bg-repeat w-full h-[64rem]"
              alt="Home"
              height="auto"
              width="100%"
            />
          </div>
          {/* Background Image End */}
        </div>
      </div>

      {/* Footer Start */}
      <Footer />
      {/* Footer End */}
    </>
  );
};

export default Mfa;
