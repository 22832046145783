import {
  characterAndFewSpecialCharacters,
  emptyStringRegex,
  onlyCharacterAndDotRegex,
  userNameRegex
} from '../regex';

export const AWS_SIGNIN_URL =
  'https://signin.aws.amazon.com/federation?Action=login&Destination=https://console.aws.amazon.com/codecommit/&SigninToken=';

export const removeDuplicates = (arr: any[]) => {
  const uniqueEmails = new Set();
  return arr.filter((obj) => {
    const isDuplicate = uniqueEmails.has(obj.email);
    uniqueEmails.add(obj.email);
    return !isDuplicate;
  });
};
// Takes string as argument and returns true if so error else returns a error message
export const validateNamePattern = (v: string): string | boolean => {
  if (!v.trim()) {
    return true;
  }
  if (!onlyCharacterAndDotRegex.test(v)) {
    return 'Numbers and special characters are not allowed except .';
  }
  if (!userNameRegex.test(v)) {
    return 'Invalid placement of dot . in Name';
  }
  return true;
};
// Takes string as argument and returns true if so error else returns a error message
export const validateAddressPattern = (v: string): string | boolean => {
  if (!v.trim()) {
    return true;
  }
  if (!characterAndFewSpecialCharacters.test(v)) {
    return 'Can not use special characters except / , . - ';
  }
  return true;
};
// Takes string as argument and returs true if string not empty else returns false
export const isNotEmpty = (v: string): boolean => {
  return emptyStringRegex.test(v);
};
