import React, { useState } from 'react';
import CustomPopup from './CustomPopup';
import PartnerDomainList from '../register/PartnerDomainList';
import InputField from '../../library/inputField/inputField';
import UpdateOrganization from '../../utils/OrgUser/UpdateOrgAdmin';
import { Button } from 'rsuite';

interface OrgUpdateProps {
  // disabled: boolean;
  name: string | null;
  email: string | null;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  userTitle: string | any;
  userFname: string | any;
  userLname: string | any;
  orgPartnerCompanyName: string | any;
  orgCompanyHaedquaterAddress: string | any;
  orgDescription: string | any;
  // isLoading: boolean;
  domains: any;
  emailList: string[];
}

export const OrgUpdate: React.FC<OrgUpdateProps> = ({
  //  disabled,
  name,
  email,
  show,
  setShow,
  userTitle,
  userFname,
  userLname,
  orgPartnerCompanyName,
  orgCompanyHaedquaterAddress,
  orgDescription,
  domains,
  emailList
  // isLoading
}) => {
  const [orgDomains, setOrgDomains] = useState(domains);
  const [newDomain, setNewDomain] = useState('');
  // Method to close the popup on clicking the close button
  const popupCloseHandler = (e: boolean) => {
    setShow(e);
  };

  const updateDomains = async (updatedDomains: any) => {
    const fullname = userFname + ' ' + userLname;
    const orgEmail = email ?? '';
    const userObj = new UpdateOrganization({
      email: orgEmail,
      fullname,
      jobTitle: userTitle,
      partnerCompanyName: orgPartnerCompanyName,
      companyHaedquaterAddress: orgCompanyHaedquaterAddress,
      description: orgDescription,
      domains: updatedDomains
    });
    // API responses have been merged since actions are same
    const updateStatus = await userObj.updateOrgAdmin();
    if (updateStatus.statusCode === 201) {
      setOrgDomains(updatedDomains);
    }
    return updateStatus;
  };

  const checkWhitespace = (str: string) => {
    return /\s/.test(str);
  };

  const urlRegex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
  const handleAddDomain = () => {
    if (orgDomains.includes(newDomain)) {
      alert('Sorry. This domain already exists!!');
    } else if (checkWhitespace(newDomain)) {
      alert("Sorry. you can't use whitespace!!");
    } else if (!urlRegex.test(newDomain)) {
      alert('Sorry. this is not a valid domain.');
    } else {
      orgDomains.push(newDomain);
      void updateDomains(orgDomains);
      setNewDomain('');
    }
  };
  return (
    <CustomPopup onClose={popupCloseHandler} show={show} width={'w-41%'}>
      <div className="w-fit h-fit">
        <div className="flex start justify-between px-4 py-4 border-b border-black">
          <h4 className="mb-0 leading-normal text-2xl text-black">{name}</h4>
        </div>
        <div className="flex flex-row justify-between m-2.5">
          <InputField
            id={'domain-add'}
            type="text"
            placeholder={'Enter your domain'}
            className="border-black p-[4.8px 4.8px] rounded-lg pl-[6px]"
            style={{ border: '1px solid gray' }}
            name="s"
            value={newDomain}
            onChange={(event) => {
              event.preventDefault();
              setNewDomain(event.target.value);
              // setIsDuplicateDomain(false);
            }}
          />
          <Button
            disabled={newDomain === ''}
            className={
              newDomain === ''
                ? 'px-4 py-[1.75] bg-offwhite text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3 cursor-not-allowed'
                : 'px-4 py-[1.75] bg-white hover:bg-black hover:text-white text-[#2c323f] rounded focus:outline-none border border-spacing-1 border-black ml-3'
            }
            onClick={handleAddDomain}>
            Add{' '}
          </Button>
        </div>
        <div className="position-relative grow shrink basis-auto  m-2.5 block max-h-[30rem]">
          {domains?.length > 0 && emailList?.length > 0 ? (
            <PartnerDomainList
              domains={orgDomains}
              emailList={emailList}
              onDomainDelete={(domains) => {
                void updateDomains(domains);
                // setOrgDomains(domains);
              }}
            />
          ) : (
            <div
              className="px-2 py-2 text-center"
              style={{ paddingTop: '1rem', fontWeight: 'bold' }}>
              <h1 className="text-bold">Please wait !!</h1>
            </div>
          )}
        </div>
      </div>
    </CustomPopup>
  );
};

export default OrgUpdate;
